import { IQRCodeModalOptions } from "@walletconnect/types";

import * as browserLib from "./browser";

function open(uri: string, cb: any, qrcodeModalOptions?: IQRCodeModalOptions) {
  // eslint-disable-next-line no-console
  console.log(uri);
  browserLib.open(uri, cb, qrcodeModalOptions);
}

function close() {
  browserLib.close();
}

export default { open, close };
